/* eslint-disable no-console */
<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import Stat from "@/components/widgets/stat";
import UserService from "@/services/user";
// import Transaction from "@/components/widgets/transaction";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, Stat, /*Transaction*/ },
  data() {
    return {
      title: "Dashboard",
      statData: [
        // {
        //   icon: "bx bx-copy-alt",
        //   title: "Orders",
        //   value: "1,235"
        // },
        // {
        //   icon: "bx bx-archive-in",
        //   title: "Revenue",
        //   value: "$35, 723"
        // },
        // {
        //   icon: "bx bx-purchase-tag-alt",
        //   title: "Average Price",
        //   value: "$16.2"
        // }
      ],
      transactions: [
        // {
        //   id: "#SK2540",
        //   name: "Neal Matthews",
        //   date: "07 Oct, 2019",
        //   total: "$400",
        //   status: "Paid",
        //   payment: ["fa-cc-mastercard", "Mastercard"],
        //   index: 1
        // },
        // {
        //   id: "#SK2541",
        //   name: "Jamal Burnett",
        //   date: "07 Oct, 2019",
        //   total: "$380",
        //   status: "Chargeback",
        //   payment: ["fa-cc-visa", "Visa"],
        //   index: 2
        // },
        // {
        //   id: "#SK2542",
        //   name: "Juan Mitchell",
        //   date: "06 Oct, 2019",
        //   total: "$384",
        //   status: "Paid",
        //   payment: ["fab fa-cc-paypal", "Paypal"],
        //   index: 3
        // },
        // {
        //   id: "#SK2543",
        //   name: "Barry Dick",
        //   date: "05 Oct, 2019",
        //   total: "$412",
        //   status: "Paid",
        //   payment: ["fa-cc-mastercard", "Mastercard"],
        //   index: 4
        // },
        // {
        //   id: "#SK2544",
        //   name: "Ronald Taylor",
        //   date: "04 Oct, 2019",
        //   total: "$404",
        //   status: "Refund",
        //   payment: ["fa-cc-visa", "Visa"],
        //   index: 5
        // },
        // {
        //   id: "#SK2545",
        //   name: "Jacob Hunter",
        //   date: "04 Oct, 2019",
        //   total: "$392",
        //   status: "Paid",
        //   payment: ["fab fa-cc-paypal", "Paypal"],
        //   index: 6
        // }
      ]
    };
  },
  mounted() {
    this.getStats();
  },
  methods: {
    async getStats() {
      const loader = this.$loading.show();

      try {
        const result = await UserService.getStats();
        this.statData = result.data;

        loader.hide();

        // eslint-disable-next-line no-console
        console.log(this.statData);
      } catch (err) {
        loader.hide();
        
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
  }
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Dashboard</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Últimos Serviços</h4>
            <Transaction :transactions="transactions" />
          </div>
        </div>
      </div>
    </div> -->
  </Layout>
</template>
